<template>
    <div class="pb-20 pt-20">
        <div v-if="isLoading.loading" class="loading-overlay">
            <div class="loader"></div>
        </div>
        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">

            <div class="bg-white rounded-xl shadow-md p-5">
                <div class="text-base font-semibold">MASUKKAN NOMINAL DONASI</div>
                <div class="border-2 border-gray-200 mt-3 mb-2"></div>

                <div class="mb-2">
                    <label class="mt-2 text-sm">
                        <i class="fa fa-money-bill-wave text-yellow-500" aria-hidden="true"></i> Rp.
                    </label>
                    <vue-mask 
                        id="inputAmount"
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 text-right font-medium text-sm"
                        v-model="donation.amount"
                        mask="000.000.000.000.000.000.000.000.000.000"
                        :options="optionsVuewMask"
                        placeholder="0"
                        autofocus
                    ></vue-mask>
                    <p style="color:red" class="error-message" v-if="donation.amount > 0 && donation.amount < 25000">
                        Jumlah minimal Donasi adalah <b>Rp. 25.000</b>.
                    </p>
                </div>

                <div class="mb-4 grid grid-cols-2 md:grid-cols-4 gap-3">
                    <button @click="setAmount(100000)" class="bg-yellow-500 md:m-0 px-2 py-1 rounded-md shadow-md text-sm text-white w-auto font-bold focus:outline-none focus:bg-yellow-600">Rp. 100.000</button>
                    <button @click="setAmount(250000)" class="bg-yellow-500 md:m-0 px-2 py-1 rounded-md shadow-md text-sm text-white w-auto font-bold focus:outline-none focus:bg-yellow-600">Rp. 250.000</button>
                    <button @click="setAmount(500000)" class="bg-yellow-500 md:m-0 px-2 py-1 rounded-md shadow-md text-sm text-white w-auto font-bold focus:outline-none focus:bg-yellow-600">Rp. 500.000</button>
                    <button @click="setOtherAmount()" class="bg-yellow-500 md:m-0 px-2 py-1 rounded-md shadow-md text-sm text-white w-auto font-bold focus:outline-none focus:bg-yellow-600">Donasi Terbaik</button>
                </div>

                <div class="mb-2">
                    <label class="mt-2 text-xs">
                        <i class="fa fa-praying-hands text-yellow-500" aria-hidden="true"></i> Do'a
                    </label>
                    <textarea rows="3" v-model="donation.pray"
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-3 font-medium text-sm" placeholder="Tulis Do'a/Ucapan">
                    </textarea>
                </div>
                <!-- <button v-if="isLoggedIn" @click="createBilling" class="mt-6 bg-yellow-500 py-3 rounded-md shadow-md text-base text-white w-full uppercase font-bold focus:outline-none focus:bg-yellow-600">Selangkah lagi menuju kebaikan</button> -->
            </div>
        </div>
        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
            <div class="bg-white rounded-xl shadow-md p-5">
                <div class="text-base font-semibold">Pilih Metode Transfer</div>
                <div class="border-2 border-gray-200 mt-3 mb-2"></div>
                <label class="mt-2 text-sm">
                    <i class="fa fa-share text-yellow-500" aria-hidden="true"></i> Pilih Metode Pembayaran
                </label>
                <div class="mb-2">
                    <div class="list-group">
                        <select v-model="payMethode.sender_bank_type" 
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-3 font-medium text-sm" placeholder="Pilih metode">
                            <option disabled value="">Pilih metode...</option>
                            <option v-for="method in paymentMethods" :key="method.id" :value="method">
                            {{ method.name }}
                            </option>
                        </select>
                    </div>
                    <div v-if="payMethode.sender_bank_type.name === 'Virtual Account'" >
                    <label for="bankSelection">Pilih Bank:</label>
                    <select v-model="payMethode.sender_bank" 
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-3 font-medium text-sm" placeholder="Pilih metode">
                        <option disabled value="">Pilih bank...</option>
                        <option v-for="bank in banks" :key="bank.id" :value="bank">
                        {{ bank.name }}
                        </option>
                    </select>
                    </div>
                </div>
                <div v-if="selectedBank">
                    Anda memilih metode <strong>{{ selectedMethod }}</strong> dengan bank <strong>{{ selectedBank }}</strong>.
                </div>

                <button v-if="isLoggedIn" @click="createBilling" class="mt-6 bg-yellow-500 py-3 rounded-md shadow-md text-base text-white w-full uppercase font-bold focus:outline-none focus:bg-yellow-600">Selangkah lagi menuju kebaikan</button>
            </div>
        </div>

        <div v-if="!isLoggedIn" class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">

            <div class="bg-white rounded-xl shadow-md p-5">
                <div class="text-base font-semibold">MASUKKAN DATA DIRI ANDA</div>
                <div class="border-2 border-gray-200 mt-3 mb-2"></div>

                <div class="mb-2">
                    <label class="mt-2 text-xs">
                        <i class="fa fa-address-card text-yellow-500" aria-hidden="true"></i> Nama Lengkap
                    </label>
                    <input type="text"
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 font-medium text-sm"
                        placeholder="Nama Lengkap Anda" v-model="donaturs.name">
                </div>
                <!--
                <div class="mb-6">
                    <label class="container-custom-checkbox">
                        <span class="text-xs">
                            Terapkan sebagai Orang Baik
                        </span>
                        <input type="checkbox" @change="setGoodGuy($event)" true-value="1" false-value="0">
                        <span class="checkmark-custom-checkbox"></span>
                    </label>
                </div>
                -->

                <div class="mb-4">
                    <label class="mt-2 text-xs">
                        <i class="fa fa-mobile-alt text-yellow-500" aria-hidden="true"></i> Nomor HP / WA
                    </label>
                    <vue-mask 
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 font-medium text-sm"
                        v-model="donaturs.phoneNumber"
                        mask="0000-0000-00000"
                        placeholder="0812-1234-56789"
                    ></vue-mask>
                </div>

                <div class="mb-4">
                    <label class="mt-2 text-xs">
                        <i class="fa fa-envelope text-yellow-500" aria-hidden="true"></i> Email Anda
                    </label>
                    <input type="email"
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 font-medium text-sm"
                        placeholder="Email" v-model="donaturs.email">
                </div>

                <button @click="createBillingNonLogin" class="bg-yellow-500 mt-4 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold text-white focus:outline-none focus:bg-yellow-600">Selangkah lagi menuju kebaikan</button>
            </div>

        </div>
    </div>
</template>

<script>

    // Ganti title
    document.title = "Form Pembayaran";
    
    //hook vue
    import { computed, reactive } from 'vue'
    
    //hook vuex
    import { useStore } from 'vuex'
    
    //hook vue router
    import { useRoute, useRouter } from 'vue-router'
    
    //hook Toast
    import { useToast } from "vue-toastification"
    import vueMask from 'vue-jquery-mask';

    //toast component transaksi
    import Component from "./Amin.vue";

    export default {
        components: {
            vueMask
        },
        computed: {
            isLoggedIn() {
                const store = useStore()
                // console.log(store.getters['auth/currentUser'].id)
                return store.getters['auth/isLoggedIn'] == '' ? false : true
            }
        },
        data() {
            return {
            paymentMethods: [
                { id: 1, name: 'Virtual Account', code:'va', type:'virtual_account' },
                { id: 2, name: 'Dana', code:'dana', type:'wallet_account' },
                { id: 3, name: 'GoPay', code:'gopay', type:'wallet_account' },
                { id: 4, name: 'OVO', code:'ovo', type:'wallet_account' },
                { id: 5, name: 'LinkAja', code:'linkaja', type:'wallet_account' },
                { id: 6, name: 'QRIS', code:'qris', type:'wallet_account' }
            ],
            banks: [
                { id: 1, name: 'BCA VA', code:'bca' },
                { id: 2, name: 'Mandiri VA', code:'mandiri' },
                { id: 3, name: 'Bsi VA', code:'bsm' },
                { id: 4, name: 'Permata VA', code:'permata' },
                { id: 5, name: 'CIMB VA', code:'cimb' },
                { id: 6, name: 'BNI VA', code:'bni' },
                { id: 7, name: 'Danamon VA', code:'danamon' },
                { id: 8, name: 'BRI VA', code:'bri' }
            ],
            selectedMethod: '',
            selectedBank: '',
            };
        },
        methods: {
            selectPaymentMethod(method) {
            this.selectedMethod = method;
            }
        },
        created() {
            window.scrollTo(0, 0)
        },
        setup() {
            //store vuex
            const store = useStore()
            const isLoading = reactive({
                loading: false              // <-- data email donatur
            })
            //route
            const route = useRoute()

            //router
            const router = useRouter()
            
            //toast
            const toast = useToast()
            //state donation
            const donaturs = reactive({
                name: '',                      // <-- data nama donatur
                phoneNumber: '',               // <-- data email donatur
                email: '',                    // <-- data email donatur
            })
            const payMethode = reactive({
                sender_bank_type: {},
                sender_bank: {}                // <-- data email donatur
            })

            const optionsVuewMask = {
                reverse: true
            }

            //state donation
            const donation = reactive({
                amount: route.query.nominal || 0,                      // <-- data nilai donasi
                pray: '',                       // <-- data kata-kata/doa
                campaignSlug: route.params.slug // <-- data "slug" dari campaign
            })


            function setAmount(value) {
                donation.amount = value
            }

            function setOtherAmount() {
                if (donation.amount >= 1/*== 100000 || donation.amount == 250000 || donation.amount == 500000*/) {
                    donation.amount = ''
                }
                document.getElementById('inputAmount').focus()
            }

            function setGoodGuy(e) {
                donaturs.name = e.target.checked ? 'Orang Baik' : ''
            }

            //method store donation
            function storeDonation() {

                //check minimal donasi
                if(donation.amount < 25000) {
                    toast.error('Donasi Minimal Rp. 25.000')
                    return false
                }


                let campaign_slug = donation.campaignSlug
                let amount = donation.amount
                let pray = donation.pray

                window.fbq('track', 'AddToCart', {
                    currency: 'IDR',
                    value: amount
                });

                store.dispatch('donation/storeDonation', {
                    campaign_slug,
                    amount,
                    pray
                })
                .then(() => {

                    toast.success('Transaksi Berhasil Dibuat!')

                    //redirect ke dashboard
                    router.push({name: 'donation.index'})

                })
                .catch(error => {
                    console.log(error)
                })
            }

            function validateEmail(email) {
                const re = /\S+@\S+\.\S+/;
                return re.test(email);
            }

            window.fbq('track', 'AddPaymentInfo');

            //method store donation non login
            function storeDonationNonLogin() {

                //check minimal donasi
                if (donation.amount < 25000) {
                    if (donation.amount == 0) {
                        toast.error('Isi nominal donasi Anda, minimal Rp. 25.000')
                        return false
                    } else {
                        toast.error('Donasi minimal Rp. 25.000')
                        return false
                    }
                } else if (donaturs.name == "") {
                    toast.error('Nama harus di isi terlebih dahulu')
                    return false
                } else if (donaturs.phoneNumber == "") {
                    toast.error('Nomor HP / WA harus di isi terlebih dahulu')
                    return false
                }

                if (donaturs.email == "") {
                    donaturs.email = "support@domyadhu.org"
                }
                
                if (validateEmail(donaturs.email) == false) {
                    toast.error('Isikan Email Anda dengan benar')
                    return false
                } 

                
                let name = donaturs.name
                let phone_number = donaturs.phoneNumber
                let email = donaturs.email
                let campaign_slug = donation.campaignSlug
                let amount = donation.amount
                let pray = donation.pray

                window.fbq('track', 'AddToCart', {
                    currency: 'IDR',
                    value: amount
                });

                store.dispatch('donation/storeDonationNon', {
                    name,
                    phone_number,
                    email,
                    campaign_slug,
                    amount,
                    pray
                })
                .then(() => {
                    const donationsNonLogin = computed(() => {
                        return store.state.donation.donationsNonLogin
                    })
                    let snap_token = donationsNonLogin.value.snap_token

                    //redirect ke SNAP MIDTRANS
                    window.snap.pay(snap_token, {

                        onSuccess: function () {
                            router.push({name: 'campaign.index'})
                            toast(Component, {
                              position: "top-center",
                              timeout: false,
                              closeOnClick: false,
                              pauseOnFocusLoss: true,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.6,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: false,
                              icon: false,
                              rtl: false
                            })
                            // Pixel Donated
                            window.fbq('track', 'Donate', {
                                currency: 'IDR',
                                value: donation.amount
                            });
                            toast.success('Transaksi Berhasil!', { position: "top-center" })
                        },
                        onPending: function () {
                            router.push({name: 'campaign.index'})
                            toast(Component, {
                              position: "top-center",
                              timeout: false,
                              closeOnClick: false,
                              pauseOnFocusLoss: true,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.6,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: false,
                              icon: false,
                              rtl: false
                            })
                            toast.warning('Silakan melakukan pembayaran untuk menyelesaikan transaksi Anda', { position: "top-center" })
                        },
                        onError: function () {
                            router.push({name: 'campaign.index'})
                            toast.error('Transaksi Gagal', { position: "top-center" })
                        }
                    })

                }).catch(error => {
                    console.log(error)
                })
            }
            function createBillingNonLogin() {

                // let a = sender_bank_type
                if (payMethode.sender_bank_type == "") {
                    toast.error('Pilih methode pembayaran')
                    return false
                }
                //check minimal donasi
                if (donation.amount < 25000) {
                    if (donation.amount == 0) {
                        toast.error('Isi nominal donasi Anda, minimal Rp. 25.000')
                        return false
                    } else {
                        toast.error('Donasi minimal Rp. 25.000')
                        return false
                    }
                } else if (donaturs.name == "") {
                    toast.error('Nama harus di isi terlebih dahulu')
                    return false
                } else if (donaturs.phoneNumber == "") {
                    toast.error('Nomor HP / WA harus di isi terlebih dahulu')
                    return false
                }

                if (donaturs.email == "") {
                    donaturs.email = "support@domyadhu.org"
                }

                if (validateEmail(donaturs.email) == false) {
                    toast.error('Isikan Email Anda dengan benar')
                    return false
                } 
                let sender_bank_type;
                let sender_bank;

                if (payMethode.sender_bank_type.name === 'Virtual Account') {
                    sender_bank_type =  'virtual_account'
                    sender_bank = payMethode.sender_bank.code
                }else{
                    sender_bank_type =  'wallet_account'
                    sender_bank = payMethode.sender_bank_type.code
                }
                let name = donaturs.name
                let phone_number = donaturs.phoneNumber
                let email = donaturs.email
                let campaign_slug = donation.campaignSlug
                let amount = donation.amount
                let pray = donation.pray
                let redirect_url = "https://dompetdonasi.id"

                window.fbq('track', 'AddToCart', {
                    currency: 'IDR',
                    value: amount
                });

                isLoading.loading = true
                store.dispatch('donation/storeBillingDonationNon', {
                    name,
                    phone_number,
                    email,
                    campaign_slug,
                    amount,
                    redirect_url,
                    pray,
                    sender_bank_type,
                    sender_bank
                })
                .then(response => {
                    // const donationsNonLogin = computed(() => {
                    //     return store.state.donation.donationsNonLogin
                    // })
                    // let link_url = donationsNonLogin.value.link_url;
                    // let link = link_url;

                    // window.location.href = link;
                    
                    // isLoading.loading = false
                    // window.open(link, '_blank');
                    // router.push({name: 'donation.index'});
                    isLoading.loading = false
                    toast.success('Transaksi Berhasil Dibuat!')
                    router.push({name: 'donation.caradonasi', params:{id: response.data.donation_id}}) 
                    // if (sender_bank_type === 'virtual_account') {
                    //     router.push({name: 'donation.caradonasi', params:{id: response.data.donation_id}}) 
                    // } else {
                    //     router.push({name: 'donation.index'})
                    // }


                }).catch(error => {
                    isLoading.loading = false
                    console.log(error)
                })
            }
            //method store donation
            function createBilling() {

                // const a = store.useStore();
                
                //check minimal donasi
                if(donation.amount < 25000) {
                    toast.error('Donasi Minimal Rp. 25.000')
                    return false
                }
                let sender_bank_type;
                let sender_bank;

                if (payMethode.sender_bank_type.name === 'Virtual Account') {
                    sender_bank_type =  'virtual_account'
                    sender_bank = payMethode.sender_bank.code
                }else{
                    sender_bank_type =  'wallet_account'
                    sender_bank = payMethode.sender_bank_type.code
                }
                let idDonatur = store.getters['auth/currentUser'].id
                let name = store.getters['auth/currentUser'].name
                let phone_number = store.getters['auth/currentUser'].phone_number
                let email = store.getters['auth/currentUser'].email
                let campaign_slug = donation.campaignSlug
                let amount = donation.amount
                let pray = donation.pray
                let redirect_url = "https://dompetdonasi.id"

                window.fbq('track', 'AddToCart', {
                    currency: 'IDR',
                    value: amount
                });
                isLoading.loading = true
                store.dispatch('donation/storeBillingDonation', {
                    idDonatur,
                    name,
                    phone_number,
                    email,
                    campaign_slug,
                    amount,
                    redirect_url,
                    pray,
                    sender_bank_type,
                    sender_bank
                })
                
                
                .then(response => {
                    isLoading.loading = false
                    toast.success('Transaksi Berhasil Dibuat!')
                    router.push({name: 'donation.caradonasi', params:{id: response.data.donation_id}})
                    // isLoading.loading = false
                    // toast.success('Transaksi Berhasil Dibuat!')
                    // if (sender_bank_type === 'virtual_account') {
                    //     router.push({name: 'donation.caradonasi', params:{id: response.data.donation_id}}) 
                    // } else {
                    //     router.push({name: 'donation.index'})
                    // }
                    //redirect ke dashboard

                })
                .catch(error => {
                    isLoading.loading = false
                    console.log(error)
                })
            }
            return {
                optionsVuewMask,
                donation,       // <-- state donation
                donaturs,       // <-- state donaturs
                payMethode,
                setAmount,
                setOtherAmount,
                setGoodGuy,
                storeDonation,  // <-- method storeDonation
                storeDonationNonLogin,
                createBillingNonLogin,
                createBilling,
                isLoading
            }

        }

    }
</script>

<style>

/* Customize the label (the container) */
.container-custom-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-custom-checkbox {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-custom-checkbox:hover input ~ .checkmark-custom-checkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-custom-checkbox input:checked ~ .checkmark-custom-checkbox {
  background-color: rgba(245, 158, 11, 1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-custom-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-custom-checkbox input:checked ~ .checkmark-custom-checkbox:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-custom-checkbox .checkmark-custom-checkbox:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.list-group-item:hover {
  cursor: pointer;
  background-color: #f8f9fa;
}
.loading-overlay {
  position: fixed; /* Posisioning fixed untuk menutupi seluruh halaman */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Background putih transparan */
  display: flex;
  justify-content: center; /* Horisontal center */
  align-items: center; /* Vertical center */
  z-index: 9999; /* Pastikan overlay berada di atas semua elemen lain */
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>